import { client } from '../api/client/apiClient';

async function getAll() {
    return await client.get('/infrastructure');
}

async function getById(id) {
    return await client.get(`/infrastructure/${id}`);
}

function update(id, data) {
    return client.put(`/infrastructure/${id}`, { json: data });
}

function create(data) {
    return client.post(`/infrastructure`, { json: data });
}

function deleteInfrastructure(id) {
    return client.delete(`/infrastructure/${id}`);
}

export { getAll, getById, update, create, deleteInfrastructure };
