import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { removeSidebarItem } from '../../redux/actions/sidebar';
import { showSuccessSnackbar, showErrorSnackbar } from '../../redux/actions/snackbar';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    deleteEditInfraBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    deleteEditInfraButtonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    deleteEditInfraButton: {
        marginLeft: theme.spacing(2),
    },
}));

const InfrastructureDashboardControl = ({ infraName, infraId, handleEdit }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleDelete = async () => {
        try {
            //TODO: add deleteInfrastructure request and confirmation message
            dispatch(removeSidebarItem({ label: infraName }));
            history.push('/');
            dispatch(showSuccessSnackbar('Successfully deleted'));
        } catch (err) {
            dispatch(showErrorSnackbar('Failed to delete'));
        }
    };

    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box className={classes.deleteEditInfraButtonWrapper}>
                    <div>
                        <Typography variant="h1">{infraName} Dashboard</Typography>
                    </div>
                    <div className={classes.deleteEditInfraButtonWrapper}>
                        <Button color={'primary'} variant={'outlined'} onClick={handleEdit}>
                            Edit infra
                        </Button>
                        <Button
                            className={classes.deleteEditInfraButton}
                            color={'secondary'}
                            variant={'outlined'}
                            onClick={handleDelete}
                        >
                            Delete infra
                        </Button>
                    </div>
                </Box>
            </Grid>
        </Grid>
    );
};

export default InfrastructureDashboardControl;
