import {
    SHOW_SNACKBAR_INFO,
    SHOW_SNACKBAR_SUCCESS,
    SHOW_SNACKBAR_WARNING,
    SHOW_SNACKBAR_ERROR,
    CLOSE_SNACKBAR,
} from './actionTypes';

export const showInfoSnackbar = (message) => (dispatch) => {
    dispatch({
        type: SHOW_SNACKBAR_INFO,
        message: message,
    });
};
export const showSuccessSnackbar = (message) => (dispatch) => {
    dispatch({
        type: SHOW_SNACKBAR_SUCCESS,
        message: message,
    });
};
export const showWarningSnackbar = (message) => (dispatch) => {
    dispatch({
        type: SHOW_SNACKBAR_WARNING,
        message: message,
    });
};
export const showErrorSnackbar = (message) => (dispatch) => {
    dispatch({
        type: SHOW_SNACKBAR_ERROR,
        message: message,
    });
};

export const closeSnackbar = () => (dispatch) => {
    dispatch({ type: CLOSE_SNACKBAR });
};
