import { client } from '../api/client/apiClient';

async function getAll() {
    return await client.get('/ima01-settings');
}

async function getById(id) {
    return await client.get(`/ima01-settings/${id}`);
}

function update(id, data) {
    return client.put(`/ima01-settings/${id}`, { json: data });
}

function create(data) {
    return client.post(`/ima01-settings`, { json: data });
}

function deleteIma(id) {
    return client.delete(`/ima01-settings/${id}`);
}

export { getAll, getById, update, create, deleteIma };
