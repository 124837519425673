import { setWatchDogErrorStatus, setInputStatus, setOutputStatus } from '../actions/setm221';

const onSetm221WatchDogErrorStatusChanged = (e) => setWatchDogErrorStatus(e.id, e.watchDogErrorStatus);
const onSetm221OutputStatusChanged = (e) => setOutputStatus(e.id, e.outputStatus);
const onSetm221InputStatusChanged = (e) => setInputStatus(e.id, e.inputStatus);

const events = {
    Setm221WatchDogErrorStatusChanged: onSetm221WatchDogErrorStatusChanged,
    Setm221InputStatusChanged: onSetm221InputStatusChanged,
    Setm221OutputStatusChanged: onSetm221OutputStatusChanged,
};

export default events;
