import { combineReducers } from 'redux';
import sidebar from './sidebar';
import snackbar from './snackbar';
import setm221 from './setm221';
import watermeter from './watermeter';
import fertimeter from './fertimeter';
import jbxs from './jbxs';
import ws200WeatherStations from './ws200WeatherStations';

const allReducers = combineReducers({
    sidebar,
    snackbar,
    setm221,
    watermeter,
    fertimeter,
    jbxs,
    ws200WeatherStations,
});

export default allReducers;
