import { ADD_FERTIMETER, REMOVE_FERTIMETER, SET_FERTIMETER_COUNTER } from './actionTypes';
import { fertiMeterService } from '../../services';

function addFertimeter(fertimeter) {
    const payload = {
        id: fertimeter.deviceId,
        name: fertimeter.name,
        isConnected: fertimeter.isConnected,
        flow: fertimeter.flow,
        realFlow: fertimeter.realFlow,
        ratio: fertimeter.ratio,
        pulseRate: fertimeter.pulseRate,
    };

    return {
        type: ADD_FERTIMETER,
        payload,
    };
}

function removeFertimeter(id) {
    return {
        type: REMOVE_FERTIMETER,
        payload: { id },
    };
}

function setFertimeterCounter(id, counter) {
    return {
        type: SET_FERTIMETER_COUNTER,
        payload: { id, counter },
    };
}

function syncFertimeterState() {
    return async (dispatch) => {
        try {
            const fertimeterDevices = await fertiMeterService.getAll();
            for (const fertimeter of fertimeterDevices) {
                dispatch(addFertimeter(fertimeter));
            }
        } catch (err) {
            console.error('Unable to sync fertimeterDevices', err);
        }
    };
}

export { addFertimeter, removeFertimeter, syncFertimeterState, setFertimeterCounter };
