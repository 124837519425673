import { useDispatch } from 'react-redux';
import { Box, IconButton } from '@material-ui/core';
import { EditIcon, DeleteIcon } from '@ondo-ui/components';
import { showSuccessSnackbar, showErrorSnackbar } from '../../redux/actions/snackbar';
import { DeviceType } from '../../constants';
import {
    valveService,
    mainPumpService,
    waterMeterService,
    fertiMeterService,
    phService,
    ecService,
    setm221Service,
    ima01Service,
    mbjbxsService,
    ws200Service,
} from '../../services';
import { removeWatermeter } from '../../redux/actions/watermeter';
import { removeFertimeter } from '../../redux/actions/fertimeter';
import { removeSetm221 } from '../../redux/actions/setm221';
import { removeJbxs } from '../../redux/actions/jbxs';
import { useHistory } from 'react-router-dom';
import { removeWS200 } from '../../redux/actions/ws200';

const EditDeleteAction = ({ device, onDeviceDeleted, infraId }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const onDeleteClick = async () => {
        try {
            switch (device.type) {
                case DeviceType.Valve:
                    await valveService.deleteValve(device.id);
                    break;
                case DeviceType.MainPump:
                    await mainPumpService.deleteMainPump(device.id);
                    break;
                case DeviceType.WaterMeter:
                    await waterMeterService.deleteWaterMeter(device.id);
                    dispatch(removeWatermeter(device.id));
                    break;
                case DeviceType.FertiMeter:
                    await fertiMeterService.deleteFertiMeter(device.id);
                    dispatch(removeFertimeter(device.id));
                    break;
                case DeviceType.PhMeter:
                    await phService.deletePHMeter(device.id);
                    break;
                case DeviceType.EcMeter:
                    await ecService.deleteECMeter(device.id);
                    break;
                case DeviceType.SETM221:
                    await setm221Service.deleteSetm221(device.id);
                    dispatch(removeSetm221(device.id));
                    break;
                case DeviceType.IMA01:
                    await ima01Service.deleteIma(device.id);
                    break;
                case DeviceType.MJBXS:
                    await mbjbxsService.deleteMbjbxs(device.id);
                    dispatch(removeJbxs(device.id));
                    break;
                case DeviceType.WS200:
                    await ws200Service.deleteWS200(device.id);
                    dispatch(removeWS200(device.id));
                    break;
                default:
                    return 'Unknown type';
            }

            onDeviceDeleted();
            dispatch(showSuccessSnackbar('Successfully deleted'));
        } catch (err) {
            dispatch(showErrorSnackbar('Failed to delete'));
        }
    };

    const onEditClick = () => {
        history.push(`/infrastructures/${infraId}/edit-device/${device.type}/${device.id}`);
    };

    return (
        <Box>
            <IconButton onClick={onEditClick}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={onDeleteClick}>
                <DeleteIcon />
            </IconButton>
        </Box>
    );
};

export default EditDeleteAction;
