import { SideItemIcon } from '../../components/common';
import { infrastructureService } from '../../services';
import {
    ADD_SIDEBAR_ITEM,
    ADD_SIDEBAR_ITEMS,
    REMOVE_SIDEBAR_ITEM,
    UPDATE_SIDEBAR_ITEM,
    REMOVE_SIDEBAR_ITEMS,
} from './actionTypes';

const addSidebarItem = (item) => {
    return {
        type: ADD_SIDEBAR_ITEM,
        payload: {
            item,
        },
    };
};

const addSidebarItems = (items) => {
    return {
        type: ADD_SIDEBAR_ITEMS,
        payload: {
            items,
        },
    };
};

const updateSidebarItem = (oldItem, newItem) => {
    return {
        type: UPDATE_SIDEBAR_ITEM,
        payload: {
            oldItem,
            newItem,
        },
    };
};

const removeSidebarItem = (item) => {
    return {
        type: REMOVE_SIDEBAR_ITEM,
        payload: {
            item,
        },
    };
};

const removeAll = () => {
    return {
        type: REMOVE_SIDEBAR_ITEMS,
    };
};
const syncSidebarItems = () => async (dispatch) => {
    dispatch(removeAll());
    try {
        const infrastructures = await infrastructureService.getAll();
        infrastructures.forEach((infrastructure) => {
            dispatch(
                addSidebarItem({
                    label: infrastructure.name,
                    to: `/infrastructures/${infrastructure.id}/dashboard`,
                    icon: () => <SideItemIcon initials={infrastructure.name.charAt(0).toUpperCase()} />,
                })
            );
        });
    } catch (err) {
        console.error('Could not sync infrastructures!');
    }
};

export { addSidebarItem, addSidebarItems, updateSidebarItem, removeSidebarItem, removeAll, syncSidebarItems };
