import { Box, Dialog, DialogTitle, Divider, Typography, DialogContent } from '@material-ui/core';
import { SidemenuToolbar as OndoSidemenuToolbar, Button, NavigationContext } from '@ondo-ui/components';
import { useContext, useState } from 'react';
import { InfrastructureCreate } from '../infrastructure';

const SidemenuToolbar = () => {
    const { isDrawerOpen } = useContext(NavigationContext);
    const [open, setOpen] = useState(false);
    const onInfrastructureCreated = () => {
        setOpen(false);
    };

    return (
        <>
            <OndoSidemenuToolbar>
                {isDrawerOpen && (
                    <Box display="flex" flex={1} justifyContent="center" p={2}>
                        <Button color="primary" variant="contained" onClick={() => setOpen(true)}>
                            Add infra
                        </Button>
                    </Box>
                )}
            </OndoSidemenuToolbar>
            <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    <Typography variant="h3" align="center">
                        Add new infrastructure
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box display="flex" alignItems="center" justifyContent="center" flex={1} p={2}>
                        <InfrastructureCreate
                            onCancel={() => setOpen(false)}
                            onInfrastructureCreated={onInfrastructureCreated}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default SidemenuToolbar;
