import { useEffect, useState } from 'react';
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import SignalRContext, {
    SOCKET_URL,
    LOG_LEVEL,
    MAX_RETRY_ATTEMPTS,
    defaultHttpClient
} from '../../context/SignalRContext';
import { useInterval, useMounted } from '@ondo-ui/hooks';

const SignalRProvider = ({ children }) => {
    const isMounted = useMounted();
    const [hasAttemptedToConnect, setHasAttemptedToConnect] = useState(false);
    /**
     * @type {[import('@microsoft/signalr').HubConnection | null]}
     */
    const [client, setClient] = useState(null);
    const [clientState, setClientState] = useState(HubConnectionState.Disconnected);
    const [retryContext, setRetryContext] = useState({
        elapsedMilliseconds: 0,
        previousRetryCount: 0,
        remainingMillisecondsUntilNextRetry: 0,
        currentAttemptDelayMilliseconds: 0
    });
    /**
     * @type {[Number, import('react').Dispatch<Number>]}
     */
    const [lastRetryTimestamp, setLastRetryTimestamp] = useState(null);
    const [isRetrying, setIsRetrying] = useState(false);

    useEffect(() => {
        const client = new HubConnectionBuilder()
            .configureLogging(LOG_LEVEL)
            .withUrl(SOCKET_URL, { httpClient: defaultHttpClient })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (ctx) => {
                    // Use exponential backoff to calculate the delay for the retry attempt
                    // See: https://en.wikipedia.org/wiki/Exponential_backoff
                    const delay = 2 ** ctx.previousRetryCount * 1000;

                    if (!isMounted()) {
                        return null;
                    }

                    if (ctx.previousRetryCount + 1 === MAX_RETRY_ATTEMPTS) {
                        setRetryContext({
                            elapsedMilliseconds: ctx.elapsedMilliseconds,
                            previousRetryCount: ctx.previousRetryCount,
                            remainingMillisecondsUntilNextRetry: delay,
                            currentAttemptDelayMilliseconds: delay
                        });
                        setIsRetrying(false);
                        setLastRetryTimestamp(null);
                        // Returning null will stop the retry attempts
                        return null;
                    }

                    setRetryContext({
                        elapsedMilliseconds: ctx.elapsedMilliseconds,
                        previousRetryCount: ctx.previousRetryCount,
                        remainingMillisecondsUntilNextRetry: delay,
                        currentAttemptDelayMilliseconds: delay
                    });
                    setIsRetrying(true);
                    setLastRetryTimestamp(Date.now());

                    return delay;
                }
            })
            .build();
        // client.on('TestMessage', (data) => console.log(data));

        setClient(client);
    }, [isMounted]);

    // Handle connect/disconnect
    useEffect(() => {
        async function connect() {
            if (!client) {
                return;
            }

            if (clientState === HubConnectionState.Disconnected && !hasAttemptedToConnect) {
                try {
                    setHasAttemptedToConnect(true);
                    setClientState(HubConnectionState.Connecting);
                    await client.start();
                    setClientState(HubConnectionState.Connected);
                } catch (err) {
                    console.error('Could not connect to signalR hub!', err);
                    setClientState(HubConnectionState.Disconnected);
                }
            }
        }

        connect();
    }, [client, clientState, hasAttemptedToConnect]);

    // Update remainingMillisecondsUntilNextRetry
    useInterval(
        () => {
            const remainingMillisecondsUntilNextRetry =
                retryContext.currentAttemptDelayMilliseconds - (Date.now() - lastRetryTimestamp);

            setRetryContext((prev) => ({
                ...prev,
                remainingMillisecondsUntilNextRetry
            }));
        },
        isRetrying ? 1000 : null
    );

    // Update isRetrying state
    useEffect(() => {
        if (clientState === HubConnectionState.Disconnected || clientState === HubConnectionState.Connected) {
            setIsRetrying(false);
            setLastRetryTimestamp(null);
        }
    }, [clientState]);

    useEffect(() => {
        if (!client) {
            return;
        }

        client.onreconnecting(() => {
            client && isMounted() && setClientState(HubConnectionState.Reconnecting);
        });

        client.onreconnected(async () => {
            client && isMounted() && setClientState(HubConnectionState.Connected);
        });

        client.onclose(() => {
            client && isMounted() && setClientState(HubConnectionState.Disconnected);
        });
    }, [client, isMounted]);

    return <SignalRContext.Provider value={{ client, clientState, retryContext }}>{children}</SignalRContext.Provider>;
};

export default SignalRProvider;
