import { client } from '../api/client/apiClient';

async function getAll() {
    return await client.get('/ecmeter');
}

async function getById(id) {
    return await client.get(`/ecmeter/${id}`);
}

function update(id, data) {
    return client.put(`/ecmeter/${id}`, { json: data });
}

function create(data) {
    return client.post(`/ecmeter`, { json: data });
}

function deleteECMeter(id) {
    return client.delete(`/ecmeter/${id}`);
}

export { getAll, getById, update, create, deleteECMeter };
