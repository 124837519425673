import { Box, makeStyles, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Button } from '@ondo-ui/components';
import { DeviceType } from '../../constants';
import { useHistory } from 'react-router-dom';
import { showSuccessSnackbar, showErrorSnackbar } from '../../redux/actions/snackbar';
import { removeSidebarItem } from '../../redux/actions/sidebar';
import SimpleMenuToggle from './SimpleMenuToggle';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150
    },
    deleteEditInfraBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    deleteEditInfraButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-start'
        }
    }
}));

const InfrastructureControl = ({ infraId, infraName, handleEdit }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleAddNew = (value) => {
        history.push(`/infrastructures/${infraId}/add-device/${value}`);
    };

    const handleDelete = async () => {
        try {
            //TODO: add deleteInfrastructure request and confirmation message
            dispatch(removeSidebarItem({ label: infraName }));
            history.push('/');
            dispatch(showSuccessSnackbar('Successfully deleted'));
        } catch (err) {
            dispatch(showErrorSnackbar('Failed to delete'));
        }
    };

    const classes = useStyles();

    /**
     * @type {import('@ondo-ui/components').SelectProps['options']}
     */
    const deviceOptions = Object.keys(DeviceType).map((name) => ({
        label: name,
        key: DeviceType[name]
    }));

    return (
        <Grid container spacing={2}>
            <Grid item lg={8} sm={6} xs={12}>
                <SimpleMenuToggle label="Add new device" options={deviceOptions} onSelect={handleAddNew} />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
                <Box className={classes.deleteEditInfraButtonWrapper}>
                    <Button color={'primary'} variant={'outlined'} onClick={handleEdit}>
                        Edit infra
                    </Button>
                    <Button
                        style={{ marginLeft: '10px' }}
                        color={'secondary'}
                        variant={'outlined'}
                        onClick={handleDelete}
                    >
                        Delete infra
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default InfrastructureControl;
