import { ADD_WATERMETER, REMOVE_WATERMETER, SET_WATERMETER_COUNTER } from '../actions/actionTypes';

const initialState = {};

function watermeterReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_WATERMETER: {
            return { ...state, [payload.id]: payload };
        }
        case REMOVE_WATERMETER: {
            const { [payload.id]: _, ...newState } = state;
            return newState;
        }
        case SET_WATERMETER_COUNTER: {
            const { id, counter } = payload;
            const watermeter = state[id];
            const updatedWatermeter = { ...watermeter, counter: counter };
            return { ...state, [id]: updatedWatermeter };
        }
        default: {
            return state;
        }
    }
}

export default watermeterReducer;
