import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SignalRContext from '../../context/SignalRContext';
import { HubConnectionState } from '@microsoft/signalr';
import { useCallback } from 'react';
import StateContext from '../../context/StateContext';
import { actions } from '../../redux/actions';
import { bindActionsToSocketEvents } from '../../utils/bindActionsToSocketEvents';
import { setm221, watermeter, fertimeter, jbxs, ws200 } from '../../redux/socket';
import { bindActionCreators } from 'redux';

const StateProvider = ({
    children,
    //Socket event handlers
    subscribeToSetm221Events,
    unsubscribeFromSetm221Events,
    subscribeToWatermeterEvents,
    unsubscribeFromWatermeterEvents,
    subscribeToFertimeterEvents,
    unsubscribeFromFertimeterEvents,
    subscribeToJbxsEvents,
    unsubscribeFromJbxsEvents,
    subscribeToWS200Events,
    unsubscribeFromWS200Events,
    //Sync actions
    syncSetm221State,
    syncSidebarItems,
    syncWatermeterState,
    syncFertimeterState,
    syncJbxsState,
    syncWS200State,
}) => {
    const { client, clientState } = useContext(SignalRContext);
    const [syncingState, setSyncingState] = useState(false);
    const [hasSubscriptions, setHasSubscriptions] = useState(false);

    const syncState = useCallback(async () => {
        try {
            setSyncingState(true);
            await Promise.all([
                syncSetm221State(),
                syncSidebarItems(),
                syncWatermeterState(),
                syncFertimeterState(),
                syncJbxsState(),
                syncWS200State(),
            ]);
            setSyncingState(false);
        } catch (err) {
            setSyncingState(false);
            throw err;
        }
    }, [syncSetm221State, syncSidebarItems, syncWatermeterState, syncFertimeterState, syncJbxsState, syncWS200State]);

    useEffect(() => {
        if (!client) {
            return;
        }

        client.onreconnected(async () => {
            try {
                await syncState();
            } catch (err) {
                console.error('Cannot sync state after reconnecting', err);
            }
        });
    }, [syncState, client]);

    useEffect(() => {
        if (!client) {
            // Bail out if no client is instantiated
            return;
        }

        async function subscribe() {
            if (clientState === HubConnectionState.Connected && !hasSubscriptions) {
                await syncState();

                subscribeToSetm221Events(client);
                subscribeToWatermeterEvents(client);
                subscribeToFertimeterEvents(client);
                subscribeToJbxsEvents(client);
                subscribeToWS200Events(client);
                setHasSubscriptions(true);
            }
        }

        function unsubscribe() {
            if (hasSubscriptions) {
                setHasSubscriptions(false);
                unsubscribeFromSetm221Events(client);
                unsubscribeFromWatermeterEvents(client);
                unsubscribeFromFertimeterEvents(client);
                unsubscribeFromJbxsEvents(client);
                unsubscribeFromWS200Events(client);
            }
        }

        subscribe();

        return unsubscribe;
    }, [
        client,
        clientState,
        hasSubscriptions,
        syncState,
        subscribeToSetm221Events,
        unsubscribeFromSetm221Events,
        subscribeToWatermeterEvents,
        unsubscribeFromWatermeterEvents,
        subscribeToFertimeterEvents,
        unsubscribeFromFertimeterEvents,
        subscribeToJbxsEvents,
        unsubscribeFromJbxsEvents,
        subscribeToWS200Events,
        unsubscribeFromWS200Events,
    ]);

    return <StateContext.Provider value={{ syncingState }}>{children}</StateContext.Provider>;
};

function mapDispatchToProps(dispatch) {
    const syncActions = {
        syncSetm221State: actions.setm221.syncSetm221State,
        syncSidebarItems: actions.sidebar.syncSidebarItems,
        syncWatermeterState: actions.watermeter.syncWatermeterState,
        syncFertimeterState: actions.fertimeter.syncFertimeterState,
        syncJbxsState: actions.jbxs.syncJbxsState,
        syncWS200State: actions.ws200.syncWS200State,
    };

    const [subscribeToSetm221Events, unsubscribeFromSetm221Events] = bindActionsToSocketEvents(setm221, dispatch);
    const [subscribeToWatermeterEvents, unsubscribeFromWatermeterEvents] = bindActionsToSocketEvents(
        watermeter,
        dispatch
    );
    const [subscribeToFertimeterEvents, unsubscribeFromFertimeterEvents] = bindActionsToSocketEvents(
        fertimeter,
        dispatch
    );
    const [subscribeToJbxsEvents, unsubscribeFromJbxsEvents] = bindActionsToSocketEvents(jbxs, dispatch);

    const [subscribeToWS200Events, unsubscribeFromWS200Events] = bindActionsToSocketEvents(ws200, dispatch);

    return {
        //SETM221
        subscribeToSetm221Events,
        unsubscribeFromSetm221Events,
        //Watermeter
        subscribeToWatermeterEvents,
        unsubscribeFromWatermeterEvents,
        //Fertimeter
        subscribeToFertimeterEvents,
        unsubscribeFromFertimeterEvents,
        //Jbxs
        subscribeToJbxsEvents,
        unsubscribeFromJbxsEvents,
        //WS200
        subscribeToWS200Events,
        unsubscribeFromWS200Events,
        //Sync
        ...bindActionCreators(syncActions, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(StateProvider);
