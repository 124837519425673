import { client } from '../api/client/apiClient';

async function getAll() {
    return await client.get('/fertimeter');
}

async function getById(id) {
    return await client.get(`/fertimeter/${id}`);
}

function update(id, data) {
    return client.put(`/fertimeter/${id}`, { json: data });
}

function create(data) {
    return client.post(`/fertimeter`, { json: data });
}

function deleteFertiMeter(id) {
    return client.delete(`/fertimeter/${id}`);
}

export { getAll, getById, update, create, deleteFertiMeter };
