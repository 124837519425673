import urlJoin from 'url-join';
import { HttpRequestError } from './errors';

/**
 * @typedef ClientRequestInit
 * @property {Object} [json] - JSON body payload
 * @property {Boolean} [options.raw] - This will return the raw fetch Response object
 * @property {Number} [options.timeout] - Request timeout
 */

/**
 * Before request hook
 * @callback BeforeRequestHook
 * @param {Request} - Original request
 * @returns {Promise<Request>}
 */

/**
 * Error response hook
 * @callback ErrorResponseHook
 * @param {HttpRequestError} - The error object
 * @param {Response} - Original response
 * @returns {Promise<Response>}
 */

/**
 * Alters a request object and returns a new Request instance.
 * @param {Request} request
 * @param {RequestInit} newProps
 * @returns {Promise<Request>}
 */
async function alterRequest(request, newProps) {
    const origRequestBody = request.headers.get('Content-Type') ? await request.blob() : undefined;

    return new Request(request.url, {
        mode: newProps.mode || request.mode,
        body: newProps.body || origRequestBody,
        cache: newProps.cache || request.cache,
        credentials: newProps.credentials || request.credentials,
        headers: newProps.headers || request.headers,
        integrity: newProps.integrity || request.integrity,
        keepalive: newProps.keepalive || request.keepalive,
        method: newProps.method || request.method,
        redirect: newProps.redirect || request.redirect,
        referrer: newProps.referrer || request.referrer,
        referrerPolicy: newProps.referrerPolicy || request.referrerPolicy,
        signal: newProps.signal || request.signal,
        window: null,
    });
}

/**
 * Merges two Headers instances
 * @param {Headers} a
 * @param {Headers} b
 */
function mergeHeaders(a, b) {
    const mergedHeaders = new Headers(a);

    for (const [headerKey, headerValue] of b.entries()) {
        if (a.has(headerKey)) {
            mergedHeaders.append(headerKey, headerValue);
        } else {
            mergedHeaders.set(headerKey, headerValue);
        }
    }

    return mergedHeaders;
}

/**
 * Aims to transform fetch response to a valid js object if Content-Type header is set
 * @param {Response} response
 * @returns {Object|String|Response['body']} - Mapped response body
 */
async function mapResponseBody(response) {
    const headers = response.headers;
    const contentType = headers.get('Content-Type');
    const contentLength = parseInt(headers.get('Content-Length'));
    const isContentTypeJson = contentType && contentType.includes('application/json');
    const isContentTypeText = contentType && contentType.startsWith('text/');

    if (response.status === 204 || (contentLength === 0 && !contentType)) {
        // TODO: Find a better way to handle 204 No Content
        return Promise.resolve(null);
    }

    if (isContentTypeJson) {
        return response.json();
    }

    if (isContentTypeText) {
        return response.text();
    }

    // Cannot map response based on Content-Type header, thus a raw response object is returned!
    // Handling of response is left to the caller.
    return Promise.resolve(response);
}

/**
 * Returns a fetch request client
 * @param {Object} options
 * @param {String} [options.baseUrl] - Base url to use for requests
 * @param {Headers} [options.defaultHeaders] - Default headers
 * @param {Number} [options.timeout] - Timeout per request in milliseconds
 * @param {RequestInit['mode']} [options.mode]
 * @param {RequestInit['credentials']} [options.credentials]
 * @param {RequestInit['cache']} [options.cache]
 * @param {RequestInit['redirect']} [options.redirect]
 * @param {RequestInit['referrer']} [options.referrer]
 * @param {Object} [options.hooks] - Hooks which are called during the request/response cycle
 * @param {BeforeRequestHook} [options.hooks.beforeRequest] - Called before request is sent
 * @param {ErrorResponseHook} [options.hooks.onErrorResponse] - Called if the request returns with an error
 */
function makeClient(options) {
    options = options || {};

    /**
     *
     * @param {RequestInfo} input
     * @param {RequestInit & ClientRequestInit} init
     * @returns {Promise<Request>}
     */
    async function createRequest(input, init) {
        const base = options.baseUrl || window.location.toString();
        const customPath = urlJoin(base, input);

        /**
         * @type {RequestInit}
         */
        const requestOptions = {
            mode: options.mode,
            credentials: options.credentials,
            cache: options.cache,
            redirect: options.redirect,
            referrer: options.referrer,
            ...init,
        };

        const mergedHeaders = mergeHeaders(new Headers(options.defaultHeaders), new Headers(init.headers));

        requestOptions.headers = mergedHeaders;

        /**
         * Handle JSON payload automatically
         */
        if (init.json) {
            requestOptions.headers.set('Content-Type', 'application/json');
            requestOptions.body = JSON.stringify(init.json);
        }

        const timeout = options.timeout || init.timeout || 0;

        if (timeout && !requestOptions.signal) {
            const controller = new AbortController();
            setTimeout(() => controller.abort(), timeout);
            requestOptions.signal = controller.signal;
        }

        let request = new Request(customPath, requestOptions);

        if (options.hooks && options.hooks.beforeRequest && typeof options.hooks.beforeRequest === 'function') {
            request = await options.hooks.beforeRequest(request);

            if (!request || !(request instanceof Request)) {
                throw new TypeError('[beforeRequest] hook must return a Request instance!');
            }
        }

        return request;
    }

    function mapClient(method) {
        /**
         *
         * @param {RequestInfo} input
         * @param {RequestInit & ClientRequestInit} [init]
         * @returns {Promise<Response>}
         */
        async function customFetch(input, init) {
            init = init || {};

            const request = await createRequest(input, { ...init, method });

            return fetch(request).then(async (response) => {
                if (!response.ok) {
                    const responseBody = await mapResponseBody(response);
                    const requestError = new HttpRequestError(response, responseBody);
                    if (typeof options.hooks?.onErrorResponse === 'function') {
                        return options.hooks.onErrorResponse(requestError, response);
                    }
                    return Promise.reject(requestError);
                }

                if (init.raw) {
                    return Promise.resolve(response);
                }

                return mapResponseBody(response);
            });
        }

        return customFetch;
    }

    return {
        get: mapClient('GET'),
        post: mapClient('POST'),
        put: mapClient('PUT'),
        patch: mapClient('PATCH'),
        delete: mapClient('DELETE'),
    };
}

export { makeClient, alterRequest, mergeHeaders };
