import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

const ErrorPage = ({ message }) => {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography variant="h2">Error</Typography>
            </Grid>
            {message && (
                <Grid item>
                    {' '}
                    <Typography variant="body1" color="error">
                        {message}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

ErrorPage.propTypes = {
    message: PropTypes.string
};

export default ErrorPage;
