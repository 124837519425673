import { client } from '../api/client/apiClient';

async function getAll() {
    return await client.get('/setm221');
}

async function getById(id) {
    return await client.get(`/setm221/${id}`);
}

function update(id, data) {
    return client.put(`/setm221/${id}`, { json: data });
}

function create(data) {
    return client.post(`/setm221`, { json: data });
}

function deleteSetm221(id) {
    return client.delete(`/setm221/${id}`);
}

export { getAll, getById, update, create, deleteSetm221 };
