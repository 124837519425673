import { setAutoresetStatus, setRainQuantity, setRainSimulationValues, setSimulationStatus } from '../actions/ws200';

const onRainQuantityChanged = (e) => setRainQuantity(e.id, e.rainQuantity);
const onRainSimulationStatusChanged = (e) => setSimulationStatus(e.id, e.rainSimulationEnabled);
const onAutoresetStatusChanged = (e) => setAutoresetStatus(e.id, e.rainSimulationAutoresetEnabled);
const onRainSimulationValuesChanged = (e) =>
    setRainSimulationValues(e.id, {
        rainSimulationInterval: e.rainSimulationInterval,
        rainSimulationAutoresetInterval: e.rainSimulationAutoresetInterval,
    });

const events = {
    WS200RainQuantityChanged: onRainQuantityChanged,
    WS200RainSimulationChanged: onRainSimulationStatusChanged,
    WS200RainSimulationAutoresetChanged: onAutoresetStatusChanged,
    WS200RainSimulationValuesChanged: onRainSimulationValuesChanged,
};

export default events;
