import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core';
import { Button } from '@ondo-ui/components';

const SimpleMenuToggle = ({ label, labelIcon, options, onSelect, disabled = false }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (e, option) => {
        if (anchorRef.current && anchorRef.current.contains(e.target)) {
            return;
        }
        if (option) {
            onSelect(option);
        }
        setOpen(false);
    };

    function handleListKeyDown(e) {
        if (e.key === 'Tab') {
            e.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div>
            <Button
                innerRef={anchorRef}
                variant="outlined"
                color="secondary"
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                disabled={disabled}
            >
                {labelIcon ? labelIcon : null}
                {label}
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: '1000' }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {options.map((option) => (
                                        <MenuItem key={option.key} onClick={(e) => handleClose(e, option.key)}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

SimpleMenuToggle.propTypes = {
    label: PropTypes.string.isRequired,
    labelIcon: PropTypes.node,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            label: PropTypes.string
        })
    ),
    onSelect: PropTypes.func.isRequired
};

export default SimpleMenuToggle;
