import { client } from '../api/client/apiClient';
import { url } from '@ondo-ui/utils';

async function getOutputs(params) {
    return await client.get(url.withSearchParams('/output/devices/available-outputs', params));
}

async function createOutput(data) {
    return await client.post(`/output`, { json: data });
}

async function deleteOutput(id) {
    return await client.post(`/output/${id}`);
}

async function getInputs(params) {
    return await client.get(url.withSearchParams('/input/devices/available-inputs', params));
}

async function createInput(data) {
    return await client.post(`/input`, { json: data });
}

async function daleteInput(id) {
    return await client.post(`/input/${id}`);
}

export { getOutputs, createOutput, deleteOutput, getInputs, createInput, daleteInput };
