import { updateObj } from '../../utils/object';
import {
    ADD_WS200,
    REMOVE_WS200,
    SET_WS200_RAIN_QUANTITY,
    SET_WS200_RAIN_SIMULATION_STATUS,
    SET_WS200_RAIN_SIMULATION_AUTO_RESET_STATUS,
    SET_WS200_RAIN_SIMULATION_VALUES,
} from '../actions/actionTypes';

const initialState = {};

function ws200Reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_WS200: {
            return { ...state, [payload.id]: payload };
        }
        case REMOVE_WS200: {
            const { [payload.id]: _, ...newState } = state;
            return newState;
        }
        case SET_WS200_RAIN_SIMULATION_STATUS:
        case SET_WS200_RAIN_SIMULATION_AUTO_RESET_STATUS:
        case SET_WS200_RAIN_QUANTITY: {
            const { id, ...props } = payload;
            return updateObj(state, { [id]: updateObj(state[id], props) });
        }

        case SET_WS200_RAIN_SIMULATION_VALUES: {
            const {
                id,
                rainSimulationValues: { ...props },
            } = payload;
            return updateObj(state, { [id]: updateObj(state[id], props) });
        }
        default: {
            return state;
        }
    }
}

export default ws200Reducer;
