import { ADD_JBXS, REMOVE_JBXS, SET_JBXS_HUMIDITY, SET_JBXS_TEMPERATURE } from './actionTypes';
import { mbjbxsService } from '../../services';

function addJbxs(jbxs) {
    const payload = {
        id: jbxs.id,
        name: jbxs.name,
        simulationEnabled: jbxs.simulationEnabled,
        temperature: jbxs.temperature,
        humidity: jbxs.humidity,
        isConnected: jbxs.isConnected,
        baudRate: jbxs.baudRate,
    };

    return {
        type: ADD_JBXS,
        payload,
    };
}

function removeJbxs(id) {
    return {
        type: REMOVE_JBXS,
        payload: { id },
    };
}

function setTemperature(id, temperature) {
    return {
        type: SET_JBXS_TEMPERATURE,
        payload: { id, temperature },
    };
}

function setHumidity(id, humidity) {
    return {
        type: SET_JBXS_HUMIDITY,
        payload: { id, humidity },
    };
}

function syncJbxsState() {
    return async (dispatch) => {
        try {
            const jbxsDevices = await mbjbxsService.getAll();
            for (const jbxs of jbxsDevices) {
                dispatch(addJbxs(jbxs));
            }
        } catch (err) {
            console.error('Unable to sync jbxsDevices', err);
        }
    };
}

export { addJbxs, removeJbxs, syncJbxsState, setHumidity, setTemperature };
