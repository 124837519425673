import { client } from '../api/client/apiClient';

async function getAll() {
    return await client.get('/ws200');
}

async function getById(id) {
    return await client.get(`/ws200/${id}`);
}

function update(id, data) {
    return client.put(`/ws200/${id}`, { json: data });
}

function patchUpdate(id, data) {
    return client.patch(`/ws200/${id}`, { json: data });
}

function create(data) {
    return client.post(`/ws200`, { json: data });
}

function enableSimulation(id) {
    return client.put(`/ws200/${id}/enable-rain-simulation`);
}

function disableSimulation(id) {
    return client.put(`/ws200/${id}/disable-rain-simulation`);
}

function enableAutoreset(id) {
    return client.put(`/ws200/${id}/enable-rain-simulation-autoreset`);
}

function disableAutoreset(id) {
    return client.put(`/ws200/${id}/disable-rain-simulation-autoreset`);
}

function deleteWS200(id) {
    return client.delete(`/ws200/${id}`);
}

export {
    getAll,
    getById,
    update,
    create,
    deleteWS200,
    enableSimulation,
    disableSimulation,
    enableAutoreset,
    disableAutoreset,
    patchUpdate,
};
