// Sidebar actions
export const REMOVE_SIDEBAR_ITEM = 'REMOVE_SIDEBAR_ITEM';
export const ADD_SIDEBAR_ITEM = 'ADD_SIDEBAR_ITEM';
export const ADD_SIDEBAR_ITEMS = 'ADD_SIDEBAR_ITEMS';
export const UPDATE_SIDEBAR_ITEM = 'UPDATE_SIDEBAR_ITEM';
export const REMOVE_SIDEBAR_ITEMS = 'REMOVE_SIDEBAR_ITEMS';
export const SHOW_SNACKBAR_INFO = 'SHOW_SNACKBAR_INFO';
export const SHOW_SNACKBAR_SUCCESS = 'SHOW_SNACKBAR_SUCCESS';
export const SHOW_SNACKBAR_WARNING = 'SHOW_SNACKBAR_WARNING';
export const SHOW_SNACKBAR_ERROR = 'SHOW_SNACKBAR_ERROR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const ADD_SETM221 = 'ADD_SETM221';
export const REMOVE_SETM221 = 'REMOVE_SETM221';
export const SET_SETM221_ERROR_STATUS = 'SET_SETM221_ERROR_STATUS';
export const SET_SETM221_OUTPUTS = 'SET_SETM221_OUTPUTS';
export const SET_SETM221_INPUTS = 'SET_SETM221_INPUTS';
export const ADD_WATERMETER = 'ADD_WATERMETER';
export const REMOVE_WATERMETER = 'REMOVE_WATERMETER';
export const SET_WATERMETER_COUNTER = 'SET_WATERMETER_COUNTER';
export const ADD_FERTIMETER = 'ADD_FERTIMETER';
export const REMOVE_FERTIMETER = 'REMOVE_FERTIMETER';
export const SET_FERTIMETER_COUNTER = 'SET_FERTIMETER_COUNTER';
export const ADD_JBXS = 'ADD_JBXS';
export const REMOVE_JBXS = 'REMOVE_JBXS';
export const SET_JBXS_TEMPERATURE = 'SET_JBXS_TEMPERATURE';
export const SET_JBXS_HUMIDITY = 'SET_JBXS_HUMIDITY';
export const ADD_WS200 = 'ADD_WS200';
export const REMOVE_WS200 = 'REMOVE_WS200';
export const SET_WS200_RAIN_QUANTITY = 'SET_WS200_RAIN_QUANTITY';
export const SET_WS200_RAIN_SIMULATION_STATUS = 'SET_WS200_RAIN_SIMULATION_STATUS';
export const SET_WS200_RAIN_SIMULATION_AUTO_RESET_STATUS = 'SET_WS200_RAIN_SIMULATION_RESET_STATUS';
export const SET_WS200_RAIN_SIMULATION_VALUES = 'SET_WS200_RAIN_SIMULATION_VALUES';
