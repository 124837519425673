import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

let composedMiddleware = applyMiddleware(thunk);

if (process.env.NODE_ENV === 'development') {
    composedMiddleware = composeWithDevTools(applyMiddleware(thunk));
}

const store = createStore(reducers, composedMiddleware);

export default store;
