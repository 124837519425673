import { Sidemenu, Header, Layout as OndoLayout } from '@ondo-ui/components';
import SidemenuNavigation from '../navigation/SidemenuNavigation';
import SidemenuToolbar from '../navigation/SidemenuToolbar';
import SignalRProvider from '../socket/SignalRProvider';
import StateProvider from '../socket/StateProvider';
import { Snackbar } from '../snackbars';

const Layout = ({ children }) => {
    return (
        <SignalRProvider>
            <Snackbar />
            <OndoLayout
                header={<Header />}
                sidemenu={<Sidemenu toolbar={<SidemenuToolbar />} navigation={<SidemenuNavigation />} />}
            >
                <StateProvider>{children}</StateProvider>
            </OndoLayout>
        </SignalRProvider>
    );
};

export default Layout;
