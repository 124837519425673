import { infrastructureService } from '../../services';
import { FormikForm, FormikSubmitActions, FormikTextField } from '@ondo-ui/components';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { updateSidebarItem } from '../../redux/actions/sidebar';
import { showSuccessSnackbar, showErrorSnackbar } from '../../redux/actions/snackbar';
import { Box, Paper } from '@material-ui/core';
import { useHistory } from 'react-router';
import { SideItemIcon } from '../common';

const InfrastructureEdit = ({ infrastructure }) => {
    const infraId = infrastructure.id;
    const history = useHistory();
    const dispatch = useDispatch();

    const onSubmit = async (values) => {
        try {
            await infrastructureService.update(infraId, values.name);
            dispatch(
                updateSidebarItem(
                    {
                        label: infrastructure.name,
                        to: `/infrastructures/${infraId}/devices`,
                        icon: () => <SideItemIcon initials={infrastructure.name.charAt(0).toUpperCase()} />
                    },
                    {
                        label: values.name,
                        to: `/infrastructures/${infraId}/devices`,
                        icon: () => <SideItemIcon initials={values.name.charAt(0).toUpperCase()} />
                    }
                )
            );
            dispatch(showSuccessSnackbar('Successfully updated'));
            history.push(`/infrastructures/${infraId}/devices`);
        } catch (err) {
            dispatch(showErrorSnackbar('Fail to update'));
        }
    };

    const onCancel = () => {
        history.push(`/infrastructures/${infraId}/devices`);
    };

    return (
        <Box component={Paper} p={5} display={'flex'} justifyContent={'center'}>
            <Formik
                onSubmit={onSubmit}
                initialValues={{ name: infrastructure.name }}
                validate={() => Promise.resolve()}
            >
                {() => (
                    <FormikForm>
                        <FormikTextField id="name" name="name" label="Name" />
                        <FormikSubmitActions
                            labels={{
                                submit: 'Save',
                                cancel: 'Cancel'
                            }}
                            onCancel={onCancel}
                        />
                    </FormikForm>
                )}
            </Formik>
        </Box>
    );
};

export default InfrastructureEdit;
