import { updateObj } from '../../utils/object';
import {
    ADD_SETM221,
    REMOVE_SETM221,
    SET_SETM221_ERROR_STATUS,
    SET_SETM221_OUTPUTS,
    SET_SETM221_INPUTS,
} from '../actions/actionTypes';

const initialState = {};

function setm221Reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_SETM221: {
            return { ...state, [payload.id]: payload };
        }
        case REMOVE_SETM221: {
            const { [payload.id]: _, ...newState } = state;
            return newState;
        }
        case SET_SETM221_ERROR_STATUS:
        case SET_SETM221_INPUTS:
        case SET_SETM221_OUTPUTS: {
            const { id, ...props } = payload;
            return updateObj(state, { [id]: updateObj(state[id], props) });
        }

        default: {
            return state;
        }
    }
}

export default setm221Reducer;
