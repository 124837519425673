import { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import Layout from './components/layout/Layout';
import NotFound from './components/notFound/NotFound';
import { theme, Loader } from '@ondo-ui/components';
import { routes } from './routes';
import { routes as routesUtils } from '@ondo-ui/utils';
import store from './redux/store';

const App = () => {
    return (
        <Provider store={store}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Layout>
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                {routesUtils.mapRoutes(
                                    routes,
                                    ({ component: Component, path, innerRoutes = [], ...rest }) => (
                                        <Route
                                            key={path}
                                            path={path}
                                            render={(routeProps) => (
                                                <Component innerRoutes={innerRoutes} {...routeProps} />
                                            )}
                                            {...rest}
                                        />
                                    )
                                )}
                                <NotFound />
                            </Switch>
                        </Suspense>
                    </Layout>
                </ThemeProvider>
            </BrowserRouter>
        </Provider>
    );
};

export default App;
