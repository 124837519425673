import { client } from '../api/client/apiClient';

async function getAll() {
    return await client.get('/mainpump');
}

async function getById(id) {
    return await client.get(`/mainpump/${id}`);
}

function update(id, data) {
    return client.put(`/mainpump/${id}`, { json: data });
}

function create(data) {
    return client.post(`/mainpump`, { json: data });
}

function deleteMainPump(id) {
    return client.delete(`/mainpump/${id}`);
}

export { getAll, getById, update, create, deleteMainPump };
