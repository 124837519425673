import { Grid, Paper, Typography } from '@material-ui/core';
import { InfrastructureControl } from '.';
import { Table } from '@ondo-ui/components';
import { deviceTypeToLabel } from '../../utils';
import EditDeleteAction from './EditDeleteAction';
import { useHistory } from 'react-router-dom';

const InfrastructureDevices = ({ infraId, infraName, onDeviceDeleted, infrastructure }) => {
    const history = useHistory();
    const handleEdit = () => {
        history.push(`/infrastructures/${infraId}/edit`);
    };

    return (
        <Grid container spacing={2} component={Paper}>
            <Grid item xs={12}>
                <InfrastructureControl infraId={infraId} infraName={infraName} handleEdit={handleEdit} />
            </Grid>
            <Grid item xs={12}>
                <Table
                    data={infrastructure.devices}
                    columns={[
                        {
                            name: 'Device Name',
                            field: 'name',
                            format: (name) => <Typography variant="body1">{name}</Typography>
                        },
                        {
                            name: 'Device Type',
                            field: 'type',
                            format: (type) => <Typography variant="body1">{deviceTypeToLabel(type)}</Typography>
                        },
                        {
                            name: 'Connection string',
                            field: 'connectionString',
                            format: (connectionString) => (
                                <Typography variant="body1">
                                    {connectionString ? connectionString : 'wired connection'}
                                </Typography>
                            )
                        },
                        {
                            name: 'Actions',
                            field: '',
                            format: (device) => (
                                <EditDeleteAction onDeviceDeleted={onDeviceDeleted} device={device} infraId={infraId} />
                            )
                        }
                    ]}
                    labels={{
                        noData: 'No devices added'
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default InfrastructureDevices;
