import {
    ADD_SIDEBAR_ITEM,
    REMOVE_SIDEBAR_ITEM,
    UPDATE_SIDEBAR_ITEM,
    REMOVE_SIDEBAR_ITEMS,
} from '../actions/actionTypes';

let defaultItems = [];

const addSidebarItem = (items = [], payload) => {
    let { item } = payload;

    const newSidebarItems = [...items];
    newSidebarItems.push(item);

    return newSidebarItems;
};

const removeSidebarItem = (items = [], target) => {
    const newItems = [];
    for (const item of items) {
        if (item.label === target.item.label) {
            continue;
        }

        newItems.push(item);
    }

    return newItems;
};

const updateSidebarItem = (items = [], { oldItem, newItem }) => {
    const oldItemIdx = items.findIndex((item) => item.label === oldItem.label);
    if (oldItemIdx === -1) {
        return items;
    }

    const newItems = removeSidebarItem(items, { item: oldItem });
    return addSidebarItem(newItems, {
        item: newItem,
    });
};

const sidebarReducer = (state = defaultItems, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_SIDEBAR_ITEM:
            return addSidebarItem(state, payload);

        case REMOVE_SIDEBAR_ITEM:
            return removeSidebarItem(state, payload);

        case UPDATE_SIDEBAR_ITEM:
            return updateSidebarItem(state, payload);
        case REMOVE_SIDEBAR_ITEMS:
            return defaultItems;
        default:
            return state;
    }
};

export default sidebarReducer;
