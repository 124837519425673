import { ADD_WATERMETER, REMOVE_WATERMETER, SET_WATERMETER_COUNTER } from './actionTypes';
import { waterMeterService } from '../../services';

function addWatermeter(watermeter) {
    const payload = {
        id: watermeter.deviceId,
        name: watermeter.name,
        isConnected: watermeter.isConnected,
        flow: watermeter.flow,
        realFlow: watermeter.realFlow,
        ratio: watermeter.ratio,
        pulseRate: watermeter.pulseRate,
    };

    return {
        type: ADD_WATERMETER,
        payload,
    };
}

function removeWatermeter(id) {
    return {
        type: REMOVE_WATERMETER,
        payload: { id },
    };
}

function setWatermeterCounter(id, counter) {
    return {
        type: SET_WATERMETER_COUNTER,
        payload: { id, counter },
    };
}

function syncWatermeterState() {
    return async (dispatch) => {
        try {
            const watermeterDevices = await waterMeterService.getAll();
            for (const watermeter of watermeterDevices) {
                dispatch(addWatermeter(watermeter));
            }
        } catch (err) {
            console.error('Unable to sync watermeterDevices', err);
        }
    };
}

export { addWatermeter, removeWatermeter, syncWatermeterState, setWatermeterCounter };
