import { client } from '../api/client/apiClient';

async function getAll() {
    return await client.get('/dosing-channel-valve-details');
}

async function getById(id) {
    return await client.get(`/dosing-channel-valve-details/${id}`);
}

function update(id, data) {
    return client.put(`/dosing-channel-valve-details/${id}`, { json: data });
}

function create(data) {
    return client.post(`/dosing-channel-valve-details`, { json: data });
}

function deleteDosingChannel(id) {
    return client.delete(`/dosing-channel-valve-details/${id}`);
}

export { getAll, getById, update, create, deleteDosingChannel };
