class HttpRequestError extends Error {
    /**
     *
     * @param {Response} response
     * @param {Object|String|Response['body']} - Mapped response body
     */
    constructor(response, responseBody) {
        super(`${response.status} - ${response.statusText}`);
        this.name = new.target.name;
        this.status = response.status;
        this.statusText = response.statusText;
        this.responseBody = responseBody;
        this.response = response;

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, HttpRequestError);
        }
    }
}

export { HttpRequestError };
