import { SidemenuNavigation as OndoSideMenuNavigation } from '@ondo-ui/components';
import { useSelector } from 'react-redux';
import { SideItemIcon } from '../common';

const SidemenuNavigation = () => {
    const infras = useSelector((state) => state.sidebar);

    return (
        <OndoSideMenuNavigation
            items={[
                {
                    to: '/dashboard',
                    label: 'Infrastructures',
                    id: 'infrastructures',
                    icon: () => <SideItemIcon initials={'I'} />,
                    items: infras
                }
            ]}
        />
    );
};

export default SidemenuNavigation;
