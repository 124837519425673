import { ADD_WS200, REMOVE_WS200, SET_WS200_RAIN_QUANTITY, SET_WS200_RAIN_SIMULATION_VALUES } from './actionTypes';
import { ws200Service } from '../../services';

function addWS200(ws200) {
    const payload = {
        id: ws200.id,
        name: ws200.name,
        slaveAddress: ws200.slaveAddress,
        simulationEnabled: ws200.simulationEnabled,
        rainSimulationEnabled: ws200.rainSimulationEnabled,
        rainSimulationInterval: ws200.rainSimulationInterval,
        rainSimulationAutoresetEnabled: ws200.rainSimulationAutoresetEnabled,
        rainSimulationAutoresetInterval: ws200.rainSimulationAutoresetInterval,
        IsConnected: ws200.IsConnected,
        hwVersion: ws200.hwVersion,
        firmwareVersion: ws200.firmwareVersion,
        model: ws200.model,
        serialNumber: ws200.serialNumber,
        errors1: ws200.errors1,
        errors2: ws200.errors2,
        windSpeed: ws200.windSpeed / 10,
        windSpeedDeviation: ws200.windSpeedDeviation / 10,
        windDirection: ws200.windDirection,
        windDirectionDeviation: ws200.windDirectionDeviation,
        rainDrop: ws200.rainDrop,
        rainQuantity: ws200.rainQuantity,
        sunRadiation: ws200.sunRadiation,
        temperature: ws200.temperature / 10,
        temperatureOffset: ws200.temperatureOffset / 10,
        humidity: ws200.humidity / 10,
        humidityOffset: ws200.humidityOffset / 10,
        baudRate: 9600,
    };

    return {
        type: ADD_WS200,
        payload,
    };
}

function removeWS200(id) {
    return {
        type: REMOVE_WS200,
        payload: { id },
    };
}

function setRainQuantity(id, rainQuantity) {
    return {
        type: SET_WS200_RAIN_QUANTITY,
        payload: { id, rainQuantity },
    };
}
function setSimulationStatus(id, rainSimulationEnabled) {
    return {
        type: SET_WS200_RAIN_QUANTITY,
        payload: { id, rainSimulationEnabled },
    };
}
function setAutoresetStatus(id, rainSimulationAutoresetEnabled) {
    return {
        type: SET_WS200_RAIN_QUANTITY,
        payload: { id, rainSimulationAutoresetEnabled },
    };
}

function setRainSimulationValues(id, rainSimulationValues) {
    return {
        type: SET_WS200_RAIN_SIMULATION_VALUES,
        payload: { id, rainSimulationValues },
    };
}

function syncWS200State() {
    return async (dispatch) => {
        try {
            const ws200Devices = await ws200Service.getAll();
            for (const ws200 of ws200Devices) {
                dispatch(addWS200(ws200));
            }
        } catch (err) {
            console.error('Unable to sync ws200Devices', err);
        }
    };
}

export {
    addWS200,
    removeWS200,
    syncWS200State,
    setRainQuantity,
    setSimulationStatus,
    setAutoresetStatus,
    setRainSimulationValues,
};
