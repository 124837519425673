import { infrastructureService } from '../../services';
import { FormikForm, FormikSubmitActions, FormikTextField } from '@ondo-ui/components';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { addSidebarItem } from '../../redux/actions/sidebar';
import { showErrorSnackbar, showSuccessSnackbar } from '../../redux/actions/snackbar';
import { useHistory } from 'react-router-dom';

const InfrastructureCreate = ({ onCancel, onInfrastructureCreated }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const onSubmit = async (values) => {
        try {
            let item = await infrastructureService.create(values.name);
            dispatch(addSidebarItem({ label: item.name, to: `/infrastructures/${item.id}/devices` }));
            onInfrastructureCreated();
            history.push(`/infrastructures/${item.id}/devices`);
            dispatch(showSuccessSnackbar('Successfully created'));
        } catch (err) {
            dispatch(showErrorSnackbar('Failed to create'));
        }
    };

    return (
        <Formik onSubmit={onSubmit} initialValues={{ name: '' }} validate={() => Promise.resolve()}>
            {() => (
                <FormikForm>
                    <FormikTextField id="name" name="name" label="Name" />
                    <FormikSubmitActions
                        labels={{
                            submit: 'Save',
                            cancel: 'Cancel'
                        }}
                        onCancel={onCancel}
                    />
                </FormikForm>
            )}
        </Formik>
    );
};

export default InfrastructureCreate;
