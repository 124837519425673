import {
    ADD_SETM221,
    REMOVE_SETM221,
    SET_SETM221_ERROR_STATUS,
    SET_SETM221_OUTPUTS,
    SET_SETM221_INPUTS,
} from './actionTypes';
import { setm221Service } from '../../services';

function addSetm221(setm221) {
    const payload = {
        id: setm221.deviceId,
        name: setm221.name,
        mode: setm221.model,
        firmwareVersion: setm221.firmwareVersion,
        hardwareVersion: setm221.hwVersion,
        watchDogResetTimeout: setm221.watchDogResetTimeout,
        watchDogErrorStatus: setm221.watchDogErrorStatus,
        inputs: setm221.inputPosition,
        outputs: setm221.outputPosition,
    };

    return {
        type: ADD_SETM221,
        payload,
    };
}

function removeSetm221(id) {
    return {
        type: REMOVE_SETM221,
        payload: { id },
    };
}

function setWatchDogErrorStatus(id, watchDogErrorStatus) {
    return {
        type: SET_SETM221_ERROR_STATUS,
        payload: { id, watchDogErrorStatus },
    };
}

function setInputStatus(id, inputStatus) {
    return {
        type: SET_SETM221_INPUTS,
        payload: { id, inputStatus },
    };
}

function setOutputStatus(id, outputStatus) {
    return {
        type: SET_SETM221_OUTPUTS,
        payload: { id, outputStatus },
    };
}

function syncSetm221State() {
    return async (dispatch) => {
        try {
            const setm221Devices = await setm221Service.getAll();
            for (const setm221 of setm221Devices) {
                dispatch(addSetm221(setm221));
            }
        } catch (err) {
            console.error('Unable to sync setm221Devices', err);
        }
    };
}

export { addSetm221, removeSetm221, syncSetm221State, setWatchDogErrorStatus, setInputStatus, setOutputStatus };
