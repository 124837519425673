import {
    SHOW_SNACKBAR_INFO,
    SHOW_SNACKBAR_SUCCESS,
    SHOW_SNACKBAR_WARNING,
    SHOW_SNACKBAR_ERROR,
    CLOSE_SNACKBAR,
} from '../actions/actionTypes';

const initialState = {
    message: '',
    type: 'info',
    open: false,
};

const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_SNACKBAR_INFO:
            return Object.assign({}, state, {
                open: true,
                message: action.message,
                type: 'info',
            });
        case SHOW_SNACKBAR_SUCCESS:
            return Object.assign({}, state, {
                open: true,
                message: action.message,
                type: 'success',
            });
        case SHOW_SNACKBAR_WARNING:
            return Object.assign({}, state, {
                open: true,
                message: action.message,
                type: 'warning',
            });
        case SHOW_SNACKBAR_ERROR:
            return Object.assign({}, state, {
                open: true,
                message: action.message,
                type: 'error',
            });
        case CLOSE_SNACKBAR:
            return Object.assign({}, state, initialState);
        default:
            return state;
    }
};

export default snackbarReducer;
