import { client } from '../api/client/apiClient';

async function getAll() {
    return await client.get('/watermeter');
}

async function getById(id) {
    return await client.get(`/watermeter/${id}`);
}

function update(id, data) {
    return client.put(`/watermeter/${id}`, { json: data });
}

function create(data) {
    return client.post(`/watermeter`, { json: data });
}

function deleteWaterMeter(id) {
    return client.delete(`/watermeter/${id}`);
}

export { getAll, getById, update, create, deleteWaterMeter };
