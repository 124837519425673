import PropTypes from 'prop-types';

import {
    Snackbar as MUISnackBar,
    SnackbarContent as MUISnackBarContent,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import {
    Close as CloseIcon,
    CheckCircle as CheckCircleIcon,
    Warning as WarningIcon,
    Info as InfoIcon,
    Error as ErrorIcon,
} from '@material-ui/icons';
import { amber, green } from '@material-ui/core/colors';

import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    close: {
        padding: theme.spacing(0.5),
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const snackBarType = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const CustomSnackbarContent = ({ type, className, message, onClose, ...restProps }) => {
    const Icon = snackBarType[type];
    const classes = useStyles();
    return (
        <MUISnackBarContent
            className={clsx(classes[type], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...restProps}
        />
    );
};

const SnackBar = ({ message, open, onClose, type, position = {} }) => {
    return (
        <MUISnackBar
            autoHideDuration={3000}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                ...position,
            }}
            open={open}
            onClose={onClose}
        >
            <CustomSnackbarContent onClose={onClose} type={type} message={message} />
        </MUISnackBar>
    );
};

SnackBar.propTypes = {
    message: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    position: PropTypes.shape({
        vertical: PropTypes.oneOf(['bottom', 'top']),
        horizontal: PropTypes.oneOf(['left', 'right', 'center']),
    }),
    type: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

export default SnackBar;
