import { createContext } from 'react';
import { HubConnectionState, LogLevel, DefaultHttpClient, NullLogger } from '@microsoft/signalr';

const isDev = process.env.NODE_ENV === 'development';

let SOCKET_URL = process.env.REACT_APP_DOTNET_SOCKET_PATH;
if (isDev) {
    SOCKET_URL = `http://${window.location.hostname}:${process.env.REACT_APP_DOTNET_PORT}${process.env.REACT_APP_DOTNET_SOCKET_PATH}`;
}

const LOG_LEVEL = isDev ? LogLevel.Debug : LogLevel.None;
const MAX_RETRY_ATTEMPTS = 10;

class SignalRHttpClient extends DefaultHttpClient {
    constructor() {
        super(new NullLogger());
        this._defaultRequestTimeoutMilliseconds = 5 * 1000;
    }
    /** @inheritDoc */
    async send(request) {
        // Timeout for request
        if (!request.timeout) {
            request.timeout = this._defaultRequestTimeoutMilliseconds;
        }

        return super.send(request);
    }
}

const defaultHttpClient = new SignalRHttpClient();

/**
 * @typedef RetryContext
 * @type {Object}
 * @property {Number} elapsedMilliseconds - Elapsed milliseconds since start of connection retry
 * @property {Number} remainingMillisecondsUntilNextRetry - Remaining milliseconds until next retry attempt
 * @property {Number} previousRetryCount - Number of retry attempts
 * @property {Number} currentAttemptDelayMilliseconds - Current retry attempt delay in milliseconds
 */

export default createContext({
    /**
     * @type {import('@microsoft/signalr').HubConnection | null}
     */
    client: null,
    /**
     * @type {import('@microsoft/signalr').HubConnectionState}
     */
    clientState: HubConnectionState.Disconnected,

    /**
     * @type {RetryContext}
     */
    retryContext: {
        elapsedMilliseconds: 0,
        remainingMillisecondsUntilNextRetry: 0,
        currentAttemptDelayMilliseconds: 0,
        previousRetryCount: 0
    }
});

export { SOCKET_URL, LOG_LEVEL, MAX_RETRY_ATTEMPTS, defaultHttpClient };
