import { client } from '../api/client/apiClient';

async function getAll() {
    return await client.get('/valve');
}

async function getById(id) {
    return await client.get(`/valve/${id}`);
}

function update(id, data) {
    return client.put(`/valve/${id}`, { json: data });
}

function create(data) {
    return client.post(`/valve`, { json: data });
}

function deleteValve(id) {
    return client.delete(`/valve/${id}`);
}

export { getAll, getById, update, create, deleteValve };
