import { ADD_FERTIMETER, REMOVE_FERTIMETER, SET_FERTIMETER_COUNTER } from '../actions/actionTypes';

const initialState = {};

function fertimeterReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_FERTIMETER: {
            return { ...state, [payload.id]: payload };
        }
        case REMOVE_FERTIMETER: {
            const { [payload.id]: _, ...newState } = state;
            return newState;
        }
        case SET_FERTIMETER_COUNTER: {
            const { id, counter } = payload;
            const fertimeter = state[id];
            const updatedFertimeter = { ...fertimeter, counter: counter };
            return { ...state, [id]: updatedFertimeter };
        }
        default: {
            return state;
        }
    }
}

export default fertimeterReducer;
