import { client } from '../api/client/apiClient';

async function getAll() {
    return await client.get('/mbjbxs');
}

async function getById(id) {
    return await client.get(`/mbjbxs/${id}`);
}

function update(id, data) {
    return client.put(`/mbjbxs/${id}`, { json: data });
}

function create(data) {
    return client.post(`/mbjbxs`, { json: data });
}

function deleteMbjbxs(id) {
    return client.delete(`/mbjbxs/${id}`);
}

export { getAll, getById, update, create, deleteMbjbxs };
