import { Avatar, Box } from '@material-ui/core';

const SideItemIcon = ({ initials, dimensions }) => {
    return (
        <Box width={20} height={20} clone>
            <Avatar>{initials}</Avatar>
        </Box>
    );
};

export default SideItemIcon;
