import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { SnackBar } from '../../components/common';
import { actions } from '../../redux/actions';

const Snackbar = (props) => {
    return (
        <div>
            <Grid item xs={12}>
                <SnackBar
                    {...{ message: props.message, type: props.type, open: props.open }}
                    onClose={() => props.closeSnackbar()}
                    position={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                />
            </Grid>
        </div>
    );
};

export default connect((state) => Object.assign({}, state.snackbar), actions.snackbar)(Snackbar);
