import { makeClient } from '../http-client';
import { defaultConfig } from './config';

let baseUrl = `${window.location.origin}/${process.env.REACT_APP_DOTNET_API_PATH}`;
if (process.env.NODE_ENV === 'development') {
    baseUrl = `${process.env.REACT_APP_URI_SCHEME}://${window.location.hostname}:${process.env.REACT_APP_DOTNET_PORT}/${process.env.REACT_APP_DOTNET_API_PATH}`;
}

const client = makeClient({
    ...defaultConfig,
    baseUrl,
});

export { client };
