import { DeviceType } from '../constants';

const deviceTypeToLabel = (type) => {
    switch (type) {
        case DeviceType.IMA01:
            return 'IMA01';
        case DeviceType.SETM221:
            return 'SETM221';
        case DeviceType.WaterMeter:
            return 'Water Meter';
        case DeviceType.FertiMeter:
            return 'Ferti Meter';
        case DeviceType.Valve:
            return 'Valve';
        case DeviceType.MainPump:
            return 'Main Pump';
        case DeviceType.PhMeter:
            return 'PH Meter';
        case DeviceType.EcMeter:
            return 'EC Meter';
        case DeviceType.MJBXS:
            return 'Mesuring Box';
        case DeviceType.WS200:
            return 'WS200';
        default:
            return 'Unknown type';
    }
};

export default deviceTypeToLabel;
