function bindActionsToSocketEvents(events, dispatch) {
    const mappedEvents = Object.entries(events).map(([eventName, handler]) => [
        eventName,
        (event) => dispatch(handler(event)),
    ]);

    const subscribe = (socket) => mappedEvents.forEach((args) => socket.on(...args));
    const unsubscribe = (socket) => mappedEvents.forEach((args) => socket.off(...args));

    return [subscribe, unsubscribe];
}

export { bindActionsToSocketEvents };
