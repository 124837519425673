import { Container, Typography } from '@material-ui/core';

const NotFound = () => {
    return (
        <div>
            <Container component="main" maxWidth="xs" style={{ paddingTop: 100 }}>
                <Typography variant="h1">404: Page Not Found</Typography>
            </Container>
        </div>
    );
};

export default NotFound;
