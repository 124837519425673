import { setHumidity, setTemperature } from '../actions/jbxs';

const onTemperatureChanged = (e) => setTemperature(e.id, e.temperature);
const onHumidityChanged = (e) => setHumidity(e.id, e.humidity);

const events = {
    MbjbxsTemperatureChanged: onTemperatureChanged,
    MbjbxsHumidityChanged: onHumidityChanged,
};

export default events;
