import { lazy } from 'react';

export const routes = [
    {
        path: '/',
        key: 'default',
        exact: true,
        component: lazy(() => import('./views/infrastructure/Default')),
        public: true,
    },
    {
        path: '/infrastructures/:id/devices',
        key: 'infrastructure',
        exact: true,
        component: lazy(() => import('./views/infrastructure/Infrastructure')),
        public: true,
    },
    {
        path: '/infrastructures/:id/dashboard',
        key: 'dashboard',
        exact: true,
        component: lazy(() => import('./views/infrastructure/InfrastructureDashboard')),
        public: true,
    },
    {
        path: '/infrastructures/:id/edit',
        key: 'infrastructure',
        exact: true,
        component: lazy(() => import('./views/infrastructure/InfrastructureEdit')),
        public: true,
    },
    {
        path: '/infrastructures/:id/add-device/:type',
        key: 'deviceCreate',
        exact: true,
        component: lazy(() => import('./views/devices/DeviceCreate')),
        public: true,
    },
    {
        path: '/infrastructures/:infraId/edit-device/:type/:deviceId',
        key: 'deviceEdit',
        exact: true,
        component: lazy(() => import('./views/devices/DeviceEdit')),
        public: true,
    },
    // Not found
    {
        key: 'notFound',
        component: lazy(() => import('./components/notFound/NotFound')),
        public: true,
    },
];
