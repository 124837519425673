export const DeviceType = Object.freeze({
    IMA01: 1,
    WaterMeter: 2,
    MainPump: 3,
    FertiMeter: 4,
    SETM221: 5,
    Valve: 6,
    PhMeter: 7,
    MJBXS: 8,
    EcMeter: 9,
    WS200: 10,
});

export const BaudRates = [9600, 19200, 38400, 57600];

export const WatchDogErrorStatus = Object.freeze({
    OK: 0,
    Triggered: 1,
});
